<template>
  <div class="relative w-100">
    <form @submit.prevent="submitSearch">
      <div class="form-group">
        <input
          type="text"
          placeholder="Hersteller, Modellname, Produktname, EAN, VAN"
          class="form-control"
          @keydown.esc="clearValue"
          v-model.trim="form.search"
          @keyup="delay(submitSearch, $event)"
        />
        <div class="icon">
          <ph-magnifying-glass :size="20" weight="bold" />
        </div>
      </div>
    </form>
    <div v-if="openSuggest" class="suggest-list absolute">
      <div
        v-for="(suggest, index) in suggests"
        @click="selectItem(suggest)"
        :key="index"
        class="suggest-list-item"
      >
        {{ searchSuggestionsValue(suggest) }}
      </div>
    </div>
  </div>
</template>

<script>
import API_ROUTES from "@/utils/API_ROUTES.js";
export default {
  data() {
    return {
      delay: null,
      openSuggest: false,
      directSearch: false,
      loading: false,
      suggests: [],
      form: {
        search: null
      }
    };
  },
  methods: {
    clearValue() {
      this.form.search = null;
      this.suggests = [];
      this.openSuggest = false;
    },
    makeDelay: function(ms) {
      let timer = 0;
      return function(callback, ...props) {
        clearTimeout(timer);
        //eslint-disable-next-line
        timer = setTimeout(() => callback(...props), ms);
      };
    },
    searchSuggestionsValue(item) {
      const concatObject = (obj, separator) =>
        Object.values(obj)
          .filter(val => val)
          .join(separator);
      const temp = {
        lieferant_name: item.lieferant_name,
        modellname: item.modellname,
        HerstellerArtikelName: item.Attributes.HerstellerArtikelName.value
      };
      return concatObject(temp, " - ");
    },
    submitSearch(e) {
      this.directSearch = false;
      if (e.key === "Enter") {
        this.$store.dispatch("CANCEL_PENDING_REQUESTS");
        const data = {
          "@search.text": this.form.search
        };
        this.directSearch = true;
        this.openSuggest = false;
        return this.$emit("search", data);
      }
      if (this.form.search < 3) {
        this.openSuggest = false;
        return;
      }
      this.search(this.form.search);
    },
    selectItem(item) {
      this.openSuggest = false;
      const data = {
        status: false,
        keyword: this.form.search
      };
      this.$emit("emptySearch", data);
      this.$emit("selectedItem", item);
    },
    async search(keyword) {
      try {
        if (this.directSearch) {
          return;
        }
        this.products = [];
        this.loading = true;
        let response = await this.$search.get(API_ROUTES.search(keyword));
        if (response.value.length !== 0) {
          this.openSuggest = true;
          return (this.suggests = response.value);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.delay = this.makeDelay(350);
  }
};
</script>