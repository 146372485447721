<template>
  <div class="d-flex justify-content-start mh-100">
    <Sidebar />
    <div
      @scroll="onScroll"
      class="overflow-auto content d-flex flex-column vh-100"
    >
      <div class="d-flex justify-content-between py-5 px-4">
        <h5>In Bearbeitung</h5>
        <div class="summary d-none">
          <span class="bold">{{ pagination.totalCount }} CSV</span>
          <span>Dateien</span>
        </div>
      </div>

      <div v-if="disable || loading" class="loading-wrapper">
        <h4 v-if="disable" class="text-center">
          Alle fertig bearbeiteten Dateien finden Sie im Menüpunkt Ergebnisse.
        </h4>
        <div v-if="loading" class="loader"></div>
      </div>

      <div v-if="!loading && !disable" class="cards">
        <template v-for="(task, index) in filterTaskList">
          <task-item @canceled="cancelTask" :key="index" :task="task" />
        </template>
        <div v-if="loadingMore" class="loadmore-loading">
          <div class="loader loader-small"></div>
        </div>
        <div v-if="notMoreData" class="load-more-loading">
          <h4 class="text-center mb-4">KEINE WEITEREN AUFTRÄGE</h4>
        </div>
        <div class="card mt-4 d-none">
          <div class="card-header d-flex justify-content-between">
            <p>Upload date and time</p>

            <div class="d-flex justify-content-end align-items-center">
              <div class="card-info d-flex justify-content-start">
                <div class="info-box">
                  <p>Produkte gefunden</p>
                  <p>一</p>

                  <span class="text-primary">968</span>
                </div>
                <div class="info-box">
                  <p>Mapping erfolgreich</p>
                  <p>一</p>

                  <span class="text-green">653</span>
                </div>
                <div class="info-box">
                  <p>In Bearbeitung</p>
                  <p>一</p>
                  <span class="text-green">315</span>
                </div>
                <div class="info-box">
                  <p>
                    Keine <br />
                    Treffer
                  </p>
                  <p>一</p>
                  <span class="text-red">235</span>
                </div>
                <div class="info-box">
                  <p>Klärfall</p>
                  <p class="plug unselectable">Klärfall</p>
                  <p>一</p>
                  <span class="text-dark-grey">80</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-end">
              <div class="file-details">
                <span class="name">Filename</span>
                <span class="format">.csv</span>
              </div>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 100%"
                :aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div
            class="
              card-footer
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div
              class="percent d-flex justify-content-start align-items-center"
            >
              <span>%{{ 100 }}</span>
              <p>Produkt Mapping in Bearbeitung…</p>
            </div>
            <div
              class="card-actions d-flex justify-content-end align-items-center"
            >
              <div
                class="
                  action
                  close
                  d-none
                  justify-content-end
                  align-items-center
                "
              >
                <ph-x-circle :size="18" weight="bold" />
                <p class="link">Abbrechen</p>
              </div>
              <button class="action btn btn-white">
                <ph-arrow-right :size="18" weight="bold" />
                Keine Treffer bearbeiten
              </button>
              <button class="action btn btn-white">
                <ph-arrow-right :size="18" weight="bold" />
                Klärfälle bearbeiten
              </button>
              <button class="action btn btn-primary">
                <ph-download-simple :size="18" weight="bold" />
                Produkte Exportieren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskItem from "@/components/TaskItem.vue";
import API_ROUTES from "@/utils/API_ROUTES.js";

export default {
  name: "Processing",
  components: {
    TaskItem
  },
  data() {
    return {
      disable: false,
      notMoreData: false,
      errorTasks: [],
      loading: true,
      busy: false,
      taskList: [],
      pagination: {},
      currentPage: 1,
      scrolledToBottom: false,
      loadingMore: false
    };
  },
  computed: {
    filterTaskList() {
      return this.taskList;
    }
  },
  created() {
    this.getTaskList();
  },
  methods: {
    cancelTask(id) {
      this.taskList = this.taskList.filter(item => item.resource_id !== id);
      if (this.taskList.length === 0) {
        this.disable = true;
      }
    },
    errorTask(val) {
      this.errorTasks.push(val);
    },
    isHideTask(id) {
      let isHave = this.errorTasks.findIndex(item => item.resource_id === id);
      console.log(isHave);
      if (isHave !== -1) {
        return false;
      }
      return true;
    },

    async loadMore() {
      try {
        if (this.taskList.length === this.pagination.totalCount) {
          return (this.notMoreData = true);
        }
        if (
          this.currentPage ===
          Math.ceil(this.pagination.totalCount / this.pagination.limit)
        ) {
          return (this.notMoreData = true);
        }
        this.currentPage++;
        let response = await this.$axios.get(
          API_ROUTES.getTaskList("pending", this.currentPage)
        );
        if (response.status === "success") {
          if (response.data.tasks.items.length === 0) {
            return (this.disable = true);
          }
          for (let item of response.data.tasks.items) {
            this.$set(item, "hidden", false);
            try {
              const res = await this.$axios.get(API_ROUTES.getProgress(item.resource_id))
              item.current = res.data[0] ? res.data[0].payload.current : 0
            } catch (e) {  /**/ }
            this.taskList.push(item);
          }
          this.pagination = response.data.tasks.paginator;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.loadingMore = false;
      }
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadingMore = true;
        this.loadMore();
      }
    },
    async getTaskList() {
      try {
        if (this.currentPage === 1) {
          this.loading = true;
        }
        let response = await this.$axios.get(
          API_ROUTES.getTaskList("pending", this.currentPage)
        );
        if (response.status === "success") {
          if (response.data.tasks.items.length === 0) {
            return (this.disable = true);
          }
          for (let item of response.data.tasks.items) {
            this.$set(item, "hidden", false);
            try {
              const res = await this.$axios.get(API_ROUTES.getProgress(item.resource_id))
              item.current = res.data[0] ? res.data[0].payload.current : 0
            } catch (e) {  /**/ }
            this.taskList.push(item);
          }
          this.pagination = response.data.tasks.paginator;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async getTaskDetail() {
      try {
        for (let item of this.taskList) {
          console.log(item);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 40px;
}
.summary {
  span {
    font-size: 14px;
    margin-left: 2px;
    color: #909090;
  }
}
</style>
