<template>
  <div class="overflow-auto content d-flex flex-column vh-100 p-0">
    <Sidebar />
    <div class="manual-mapping-body overflow-hidden">
      <div class="manual-mapping-body-header">
        <div class="left">
          <h4 class="title">MANUELLES ARTIKELDATENMAPPING</h4>
        </div>
        <div class="right">
          <div class="percents">
            <template v-for="(item, index) in groupArtikles">
              <div
                :key="index"
                @click="groupArtikle(item.val)"
                class="item"
                :class="{
                  active: selectedPercent === item.val,
                  disable: item.productSum === 0
                }"
              >
                <div class="d-flex">
                  <ph-arrow-up
                    v-if="item.icon"
                    :size="16"
                    weight="bold"
                    class="mr-1"
                  />
                </div>
                <p class="desc">
                  Mapping für
                  <span>{{ mappingData.length }}</span> von
                  <span>{{ totalLength }}</span> Artikeln noch nicht erfolgt
                </p>
              </div>
            </template>
          </div>
          <div class="d-flex">
            <button class="btn btn-secondary my-4 mr-4" @click="saveProgress">
              Speichern
            </button>
            <div @click="exportData" class="exportien pointer my-4">
              <div class="count">
                <h4>{{ selectedMapping.length }}</h4>
              </div>
              <h4>EXPORTIEREN</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="manual-mapping-content">
        <div class="manual-mapping-content-left">
          <EmptyArticles
            :resourceName="taskName"
            ref="emptyArticles"
            @changeArticle="changeSelectedMapping"
            :selectedIndex="selectedIndex"
            :totalPage="mappingData.length"
            :data="selectedMappingData"
          />
        </div>
        <div class="manual-mapping-content-right">
          <div
            v-if="loading"
            class="loading-wrapper h-100 w-100 bg-gray h-100 z-index-1"
          >
            <div class="loader"></div>
          </div>
          <div v-if="!loading" class="manual-mapping-content-right-body h-100">
            <div class="header">
              <div class="left">
                <h4>ARTIKELSUCHE</h4>
              </div>
              <div class="right w-70">
                <search
                  ref="searchBar"
                  @search="searchBarSubmit"
                  @emptySearch="emptyResult"
                  @selectedItem="searchBarSubmit"
                />
              </div>
            </div>

            <div class="search-result h-100">
              <div
                v-if="loading"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <div class="loading-wrapper absolute h-100 z-index-1">
                  <div class="loader"></div>
                </div>
              </div>
              <div v-if="!loading && emptyData.status" class="empty-product">
                <div
                  class="
                    empty-product-head
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                  "
                >
                  <ph-smiley-sad :size="42" />
                  <h4 class="text-center mt-2">keine Ergebnisse gefunden</h4>
                  <span class="mt-3">
                    für <span>"</span>
                    <span class="text-primary">{{ emptyData.keyword }}</span>
                    <span>"</span>
                  </span>
                </div>
              </div>

              <div
                v-if="products.length === 0 && !loading && !emptyData.status"
                class="
                  d-flex
                  h-100
                  align-items-center
                  justify-content-center
                  flex-column
                "
              >
                <h4>ES IST KEIN PRODUKT AUSGEWÄHLT</h4>
                <p class="font-semibold mt-4">
                  Sie die Suchfunktion nutzen, um Ihr ausgewähltes Produkt nach
                  Hersteller, Produktname oder EAN zu finden.
                </p>
              </div>
              <div
                @scroll="onScroll"
                style="height: 100%; overflow: auto;     overflow-y: overlay;"
                v-if="products.length !== 0 && !loading && !emptyData.status"
              >
                <template v-for="(item, index) in getProducts">
                  <product-item
                    @selectSave="selectSaveProduct"
                    :data="item"
                    :key="index"
                  />
                </template>
                <div v-if="loadingMore" class="loadmore-loading">
                  <div class="loader loader-small"></div>
                </div>
                <div v-if="notMoreProducts" class="load-more-loading">
                  <h4 class="text-center mb-4">KEINE WEITEREN ARTIKEL</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast ref="toast" text="Fortschritt gespeichert!" />
  </div>
</template>

<script>
import EmptyArticles from "@/components/ManualMapping/EmptyArticles.vue";
import ProductItem from "@/components/ManualMapping/Product.vue";
import Search from "@/components/ManualMapping/Search.vue";
import API_ROUTES from "@/utils/API_ROUTES.js";
import PIAxios from "@/utils/PIAxios.js";
import axios from "axios";
import _ from "lodash";
import Toast from "../../components/Toast.vue";

export default {
  name: "ManualMapping",
  components: {
    ProductItem,
    Search,
    EmptyArticles,
    Toast
  },
  data() {
    return {
      totalLength: 0,
      loadingMore: false,
      notMoreProducts: false,
      type: null,
      selectedPercent: "all",
      taskName: "",
      iSearch: false,
      selectedIndex: 0,
      groupArtikles: [
        {
          name: "All",
          val: 80,
          productSum: null
        }
      ],
      showEmptyArticles: false,
      mappingData: [],
      selectedMappingData: {},
      orginalMappingData: [],
      slowLineUrl: null,
      searchItem: null,
      loading: false,
      sendManualMappingCount: 0,
      pageIndex: 1,
      emptyData: {
        status: false,
        keyword: null
      },
      id: null,
      form: {
        search: null
      },
      products: [],
      selectedMapping: []
    };
  },
  computed: {
    getProducts() {
      return this.products;
    }
  },
  async created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    await this.getProgress();
    this.getTaskResult();
    if (this.type !== "keineTreffer") {
      this.loading = true;
    }
  },
  methods: {
    selectSaveProduct(productData) {
      let product = _.clone(productData);
      if (product.selected) {
        let idx = this.products.findIndex(item => item._id === product._id);
        this.products[idx].selected = false;
        this.selectedMapping = this.selectedMapping.filter(
          item => item.mappingID !== this.selectedMappingData.MappingID
        );
        return console.log(this.selectedMapping);
      }
      if (!product.selected) {
        let idx = this.products.findIndex(item => item._id === product._id);
        this.products[idx].selected = true;
      }
      let mappingID = this.selectedMappingData.MappingID;
      let data = {
        mappingID,
        val: {
          percent: product.percent,
          ean: product.ean,
          van: product.van,
          _id: product._id,
          productID: product._id
        }
      };
      if (this.selectedMapping.length === 0) {
        return this.selectedMapping.push(data);
      }

      let isHave = this.selectedMapping.findIndex(
        item => item.mappingID === mappingID
      );

      if (isHave !== -1) {
        let productID = this.selectedMapping[isHave].val.productID;
        let idx = this.products.findIndex(item => item._id === productID);
        if (idx !== -1) {
          this.products[idx].selected = false;
        }

        let x = this.selectedMapping.filter(
          item => item.mappingID !== mappingID
        );
        x.push(data);
        return (this.selectedMapping = x);
      }
      this.selectedMapping.push(data);
    },
    changeSelectedMapping(type, isSubmit = false) {
      if (type === "next") {
        if (this.selectedIndex + 1 === this.mappingData.length) {
          return;
        }
        this.selectedIndex++;
      }
      if (type === "prev") {
        if (this.selectedIndex === 0) {
          return;
        }
        this.selectedIndex--;
      }

      this.selectedMappingData = this.mappingData[this.selectedIndex];
      let isHaveSelectedProduct = this.selectedMapping.findIndex(
        item => item.mappingID === this.selectedMappingData.MappingID
      );

      if (!isSubmit) {
        this.resetSearch();
      }
      if (isHaveSelectedProduct !== -1) {
        let product = {
          ...this.selectedMapping[isHaveSelectedProduct].val,
          selected: true
        };
        this.products.unshift(product);
      }
      this.iSearch = false;
      this.getGroupArtikleLength();
      if (this.type !== "keineTreffer") {
        this.searchSuggestItem(this.selectedMappingData.AISuggestion);
      }
    },
    resetSearch() {
      this.products = [];
      this.$refs.searchBar.clearValue();
    },
    searchBarSubmit(item) {
      this.searchItem = item;
      this.doSearch();
    },
    async searchSuggestItem(suggestProducts) {
      this.loading = true;
      try {
        this.search = false;
        let temp = [];
        for (let item of suggestProducts) {
          let response = await PIAxios.get(
            `/api/product/details?id=${item.uuid}`
          );
          let percent = this.selectedMappingData.AISuggestion.find(
            item => item.uuid === response._id
          );
          let selected = false;
          if (this.selectedMapping.length > 0) {
            selected = this.selectedMapping.find(
              item =>
                item.val.productID === response._id &&
                this.selectedMappingData.MappingID === item.mappingID
            );
          }

          temp.push({
            ...response,
            selected: selected ? true : false,
            percent: percent.Wahrscheinlichkeit
          });
        }

        this.products = []

        temp.sort(function(a, b) {
          return parseFloat(b.percent) - parseFloat(a.percent);
        });
        let slicedTemp = temp.slice(0, 5);
        slicedTemp.map(item => {
          this.products.push(item);
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    async doSearch(isPage = false) {
      try {
        if (isPage && this.notMoreProducts) {
          return;
        }
        if (!isPage) {
          this.notMoreProducts = false;
          this.pageIndex = 1;
          this.products = [];
          this.loading = true;
        }
        let response = await PIAxios.get(
          `/api/products/search?query=${this.searchItem["@search.text"]}&pageIndex=${this.pageIndex}`
        );
        if (response.products.length > 0) {
          this.iSearch = true;
          let temp = [];
          let tempProduct = null;
          response.products.find(product => {
            let isHave = this.mappingData[this.selectedIndex].AISuggestion.find(
              suggest => suggest._id === product._id
            );
            tempProduct = product;
            if (isHave) {
              tempProduct = {
                ...tempProduct,
                percent: 80,
                selected: false
              };
            }
            temp.push(tempProduct);
          });
          temp.map(item => {
            let isSelected = false;
            if (this.selectedMapping.length > 0) {
              isSelected = this.selectedMapping.find(
                mapData => mapData.val.productID === item._id
              );
            }
            this.products.push({
              ...item,
              selected: isSelected ? true : false
            });
          });
          this.emptyData.status = false;
        } else {
          if (!isPage) {
            this.products = [];
            this.emptyData.status = true;
            this.emptyData.keyword = this.searchItem["@search.text"];
          } else {
            this.notMoreProducts = true;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.loadingMore = false;
      }
    },
    emptyResult(val) {
      this.emptyData = {
        status: val.status,
        keyword: val.keyword
      };
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (!this.iSearch) {
        return;
      }
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadingMore = true;
        this.pageIndex++;
        this.doSearch(true);
      }
    },
    submitSearch() {
      this.search(this.form.search);
    },
    async getTaskResult() {
      try {
        let response = await this.$axios.get(API_ROUTES.taskResult(this.id));
        this.taskName = response.data.resource_name;
        if (response.data.type_a_file_url) {
          this.slowLineUrl = response.data.type_a_file_url;
          await this.getArtikles(this.slowLineUrl);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getProgress() {
      try {
        let res = await this.$axios.get(API_ROUTES.getProgress(this.id))
        if (res.data[0].payload) {
          this.selectedMapping = res.data[0].payload.mapping
          this.selectedIndex = res.data[0].payload.current
        }
      } catch (e) {
        console.log(e);
      }
    },
    saveProgress() {
      this.$axios.post(API_ROUTES.saveProgress(), { 
        resource_id: this.id, 
        payload: {
          mapping: this.selectedMapping,
          current: this.selectedIndex
        }
      }).then(res => {
        if(res.status === 'success') {
          this.$refs.toast.$el.classList.remove("err")
          this.$refs.toast.$el.innerHTML = 'Fortschritt gespeichert!'
        }
      }).catch(err => {
        this.$refs.toast.$el.classList.add("err")
        this.$refs.toast.$el.innerHTML = err
      })
      this.$refs.toast.$el.className = "show";
      setTimeout(() => { 
        this.$refs.toast.$el.classList.remove("show")
      }, 3000);
    },
    async getArtikles(url) {
      let r = await axios(url);
      this.mappingData = r.data.unmatched;
      this.totalLength = r.data.unmatched.length + r.data.matched.length;
      this.orginalMappingData = _.clone(this.mappingData);
      this.mappingData = this.mappingData.map(item => {
        item.AISuggestion = item.AISuggestion.map(suggest => {
          var temp = Object.assign({}, suggest);
          temp.Wahrscheinlichkeit = Number(
            temp.Wahrscheinlichkeit * 100
          ).toFixed(2);
          return temp;
        });
        return item;
      });
      let percentTemps = [];
      this.mappingData.map(item => {
        return percentTemps.push(item);
      });
      this.mappingData = percentTemps;
      this.selectedMappingData = this.mappingData[this.selectedIndex] || {};
      if (this.type !== "keineTreffer") {
        this.searchSuggestItem(this.selectedMappingData.AISuggestion);
      }
      this.getGroupArtikleLength();
      this.showEmptyArticles = true;
      this.$refs.emptyArticles.changeLoading(false);
    },
    async getGroupArtikleLength() {
      this.groupArtikles.map(group => {
        if (group.val !== "all") {
          let tmp = [];
          if (!this.selectedMappingData.AISuggestion) {
            return (group.productSum = tmp.length);
          }
          this.selectedMappingData.AISuggestion.map(item => {
            let isHave = item.Wahrscheinlichkeit >= group.val;
            if (isHave) {
              tmp.push(isHave);
            }
          });
          return (group.productSum = tmp.length);
        }
        if (group.val === "all") {
          group.productSum = this.selectedMappingData.AISuggestion.length;
        }
      });
      //console.log(tmp);
    },
    async groupArtikle(percent) {
      this.selectedPercent = percent;
      this.getGroupArtikleLength();
      if (percent == "all") {
        return (this.mappingData = _.clone(this.orginalMappingData));
      }
      let tmp = [];
      for (let i of this.orginalMappingData) {
        let isHave = i.AISuggestion.find(x => x.Wahrscheinlichkeit > percent);
        if (isHave) {
          tmp.push(i);
        }
      }
      this.mappingData = tmp;
      this.$refs.emptyArticles.changeSelected(0);
    },
    fileName(type) {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth();
      const day = d.getDay();
      const hours = d.getHours();
      const minutes = d.getMinutes();
      const seconds = d.getSeconds();
      let time = `${year}${month}${day}${hours}${minutes}${seconds}`;
      return `${time}-${this.taskName.split(".csv")[0]}-${type}.csv`;
    },
    async exportData() {
      if (this.selectedMapping.length === 0) {
        //TODO: Ask to Katja
        return;
      }
      this.loading = true;
      try {
        let data = {
          type: "selection",
          input: []
        };
        this.selectedMapping.map(item => {
          data.input.push({
            MappingID: item.mappingID,
            Mapping: {
              EAN: item.val.ean,
              Verbandsnummer: item.val.van
            }
          });
        });
        let response = await this.$axios.post(
          API_ROUTES.klarFallExport(this.id),
          data
        );
        if (response) {
          this.selectedMapping.map(item => {
            let isHave = this.mappingData.findIndex(
              mapData => mapData.mappingID !== item.mappingID
            );
            if (isHave !== -1) {
              this.mappingData.splice(isHave, 1);
            }
          });
          let blob = new Blob([response], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.fileName("man-mapping");
          link.click();
          this.selectedMapping = [];
          this.selectedMappingData = this.mappingData[0];
          this.sendManualMappingCount = data.input.length;
          this.products = [];
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>