<template>
  <div class="relative h-100 d-flex flex-column justify-content-between">
    <div
      v-if="loading"
      class="loading-wrapper absolute w-100 bg-white h-100 z-index-1"
    >
      <div class="loader"></div>
    </div>

    <div v-if="!loading" style="overflow: auto;  overflow-y: overlay;">
      <div class="empty-articles-header">
        <div style="head">
          <h4 class="text-primary title mb-0">
            {{ title }}
          </h4>
        </div>
        <h4 class="product-name">{{ resourceName }}</h4>
      </div>
      <div
        v-if="getSelectedMappingData.MappingID && showLess"
        class="product-info-items"
      >
        <template
          v-for="(value, name, index) in getSelectedMappingData.originalData"
        >
          <div
            v-if="index <= 5"
            :key="name"
            class="item justify-content-between"
          >
            <div class="item-left">
              <span>{{ name }}</span>
            </div>
            <div class="item-right" style="word-break: break-all">
              <span>{{ checkNullVal(value) }}</span>
            </div>
          </div>
        </template>
        <div
          @click="showLess = false"
          class=" d-none item text-center justify-content-center pointer"
        >
          Show More
        </div>
      </div>
      <div
        v-if="getSelectedMappingData.MappingID && !showLess"
        class="product-info-items"
      >
        <div
          v-for="(value, name) in getSelectedMappingData.originalData"
          :key="name"
          class="item justify-content-between"
        >
          <div class="item-left">
            <span>{{ name }}</span>
          </div>
          <div class="item-right" style="word-break: break-all">
            <span>{{ checkNullVal(value) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="product-info-pagination"
      style="padding: 26px 60px 5px 60px;"
    >
      <div class="left">
        Artikel
        <span class="current-page">{{ selectedIndex + 1 }}</span>
        von
        {{ totalPage }}
      </div>
      <div class="right">
        <div class="pagination-nav">
          <div
            class="pagination-nav-left"
            :class="{ disable: selectedIndex === 0 }"
            @click="changePage('prev')"
          >
            <ph-caret-left :size="16" weight="bold" />
          </div>
          <span>|</span>
          <div
            @click="changePage('next')"
            :class="{ disable: selectedIndex === totalPage }"
            class="pagination-nav-right"
          >
            <ph-caret-right :size="16" weight="bold" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "ARTIKEL AUS DATEI:"
    },
    resourceName: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
    selectedIndex: {
      type: Number,
      required: true
    },
    totalPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      selectedProductIndex: 0,
      mappingData: [],
      selectMapping: {},
      selectedMappingData: {},
      showLess: false,
      type: null
    };
  },
  computed: {
    getSelectedMappingData() {
      return this.data;
    }
  },
  methods: {
    changePage(type) {
      if (type === "next") {
        return this.$emit("changeArticle", "next");
      }
      if (type === "prev") {
        return this.$emit("changeArticle", "prev");
      }
    },

    checkNullVal(val) {
      if (val === "[NULL]" || val === "NULL" || val === "[null]") {
        return "-";
      }
      return val;
    },
    changeLoadingStatus() {
      this.loading = !this.loading;
    },
    changeLoading(par) {
      this.loading = par;
    }
  },
  created() {
    this.type = this.$route.query.type;
  }
};
</script>

<style>
</style>