<template>
  <div class="overflow-auto content d-flex flex-column vh-100 p-0">
    <Sidebar />
    <div class="manual-mapping-body overflow-hidden">
      <div class="manual-mapping-body-header">
        <div class="left">
          <h4 class="title">AUTOMATISCHES ARTIKELDATENMAPPING</h4>
        </div>
        <div class="right">
          <div class="percents">
            <template v-for="(item, index) in groupArtikles">
              <div
                :key="index"
                @click="groupArtikle(item.val)"
                class="item"
                :class="{
                  active: selectedPercent === item.val,
                  disable: item.productSum === 0
                }"
              >
                <div class="d-flex">
                  <div class="icon">
                    <ph-arrow-up
                      v-if="item.icon"
                      :size="16"
                      weight="bold"
                      class="mr-1"
                    />
                  </div>
                  <span class="title">{{ item.name }}</span>
                </div>

                <p class="desc">{{ item.productSum }} Artikel</p>
              </div>
            </template>
          </div>
          <div @click="exportData" class="exportien pointer">
            <div class="count">
              <h4>{{ getPercentGroupCountProdukte }}</h4>
            </div>
            <h4>EXPORTIEREN</h4>
          </div>
        </div>
      </div>

      <div class="manual-mapping-content">
        <div class="manual-mapping-content-left">
          <EmptyArticles
            :title="'ARTIKEL AUS DATEI:'"
            :resourceName="taskName"
            ref="emptyArticles"
            @changeArticle="changeSelectedMapping"
            :selectedIndex="selectedIndex"
            :totalPage="mappingData.length"
            :data="selectedMappingData"
          />
        </div>
        <div class="manual-mapping-content-right">
          <div
            v-if="loading"
            class="loading-wrapper h-100 w-100 bg-gray h-100 z-index-1"
          >
            <div class="loader"></div>
          </div>
          <div v-if="!loading" class="manual-mapping-content-right-body h-100">
            <div class="search-result h-100">
              <div
                v-if="loading"
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <div class="loading-wrapper absolute h-100 z-index-1">
                  <div class="loader"></div>
                </div>
              </div>
              <div v-if="!loading && emptyData.status" class="empty-product">
                <div
                  class="
                    empty-product-head
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                  "
                >
                  <ph-smiley-sad :size="42" />
                  <h4 class="text-center mt-2">keine Ergebnisse gefunden</h4>
                  <span class="mt-3">
                    für <span>"</span>
                    <span class="text-primary">{{ emptyData.keyword }}</span>
                    <span>"</span>
                  </span>
                </div>
              </div>

              <div
                v-if="products.length === 0 && !loading && !emptyData.status"
                class="
                  d-flex
                  h-100
                  align-items-center
                  justify-content-center
                  flex-column
                "
              >
                <h4>ES IST KEIN PRODUKT AUSGEWÄHLT</h4>
                <p class="font-semibold mt-4">
                  Sie die Suchfunktion nutzen, um Ihr ausgewähltes Produkt nach
                  Hersteller, Produktname oder EAN zu finden.
                </p>
              </div>

              <div
                style="height: 100%; overflow: auto;     overflow-y: overlay;"
                v-if="products.length !== 0 && !loading && !emptyData.status"
              >
                <template v-for="(item, index) in getProducts">
                  <product-item :showBtn="false" :data="item" :key="index" />
                </template>
                <div v-if="loadingMore" class="loadmore-loading">
                  <div class="loader loader-small"></div>
                </div>
                <div v-if="notMoreProducts" class="load-more-loading">
                  <h4 class="text-center mb-4">KEINE WEITEREN ARTIKEL</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API_ROUTES from "@/utils/API_ROUTES.js";
import ProductItem from "@/components/ManualMapping/Product.vue";
import EmptyArticles from "@/components/ManualMapping/EmptyArticles.vue";
import PIAxios from "@/utils/PIAxios.js";
import _ from "lodash";
import axios from "axios";

export default {
  name: "ManualMapping",
  components: {
    ProductItem,
    EmptyArticles
  },
  data() {
    return {
      totalLength: 0,
      loadingMore: false,
      notMoreProducts: false,
      selectedPercentGroupCount: 0,
      type: null,
      selectedPercent: 95,
      taskName: "",
      iSearch: false,
      selectedIndex: 0,
      groupArtikles: [
        {
          name: "95%",
          val: 95,
          productSum: null,
          icon: true
        },
        {
          name: "85%",
          val: 85,
          productSum: null,
          icon: true
        },
        {
          name: "75%",
          val: 75,
          productSum: null,
          icon: true
        }
      ],
      showEmptyArticles: false,
      mappingData: [],
      selectedMappingData: {},
      orginalMappingData: [],
      slowLineUrl: null,
      searchItem: null,
      loading: false,
      sendManualMappingCount: null,
      pageIndex: 1,
      emptyData: {
        status: false,
        keyword: null
      },
      id: null,
      form: {
        search: null
      },
      products: [],
      selectedMapping: []
    };
  },
  computed: {
    getProducts() {
      return this.products;
    },
    getPercentGroupCountProdukte() {
      let x = this.groupArtikles.find(
        item => item.val === this.selectedPercent
      );
      if (x) {
        return x.productSum;
      }
      return 0;
    }
  },
  created() {
    console.log(this.$route.query);
    this.id = this.$route.query.id;
    this.type = "klarfall";
    this.getTaskResult();
    this.loading = true;
  },
  methods: {
    changeSelectedMapping(type, isSubmit = false) {
      if (type === "next") {
        if (this.selectedIndex + 1 === this.mappingData.length) {
          return;
        }
        this.selectedIndex++;
      }
      if (type === "prev") {
        if (this.selectedIndex === 0) {
          return;
        }
        this.selectedIndex--;
      }

      this.selectedMappingData = this.mappingData[this.selectedIndex];

      if (!isSubmit) {
        this.resetSearch();
      }
      this.iSearch = false;

      this.getGroupArtikleLength();
    },
    resetSearch() {
      this.products = [];
    },
    searchBarSubmit(item) {
      this.searchItem = item;
      this.doSearch();
    },
    async searchSuggestItem(suggestProducts) {
      this.loading = true;
      try {
        this.search = false;
        let temp = [];

        for (let item of suggestProducts) {
          if (item.Wahrscheinlichkeit >= this.selectedPercent) {
            let response = await PIAxios.get(
              `/api/product/details?id=${item.uuid}`
            );
            let percent = this.selectedMappingData.AISuggestion.find(
              item => item.uuid === response._id
            );
            let selected = false;
            if (this.selectedMapping.length > 0) {
              selected = this.selectedMapping.find(
                item => item.val.productID === response._id
              );
            }

            temp.push({
              ...response,
              selected: selected ? true : false,
              percent: percent.Wahrscheinlichkeit
            });
          }
          temp.sort(function(a, b) {
            return parseFloat(b.percent) - parseFloat(a.percent);
          });
          this.products = temp.slice(0, 5);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async doSearch(isPage = false) {
      try {
        if (isPage && this.notMoreProducts) {
          return;
        }
        if (!isPage) {
          this.notMoreProducts = false;
          this.pageIndex = 1;
          this.products = [];
          this.loading = true;
        }
        let response = await PIAxios.get(
          `/api/products/search?query=${this.searchItem["@search.text"]}&pageIndex=${this.pageIndex}`
        );
        if (response.products.length > 0) {
          this.iSearch = true;
          let temp = [];
          let tempProduct = null;
          response.products.find(product => {
            let isHave = this.mappingData[this.selectedIndex].AISuggestion.find(
              suggest => suggest.uuid === product._id
            );
            tempProduct = product;
            if (isHave) {
              tempProduct = {
                ...tempProduct,
                percent: 80,
                selected: false
              };
            }
            temp.push(tempProduct);
          });

          temp.map(item => {
            let isSelected = false;
            if (this.selectedMapping.length > 0) {
              isSelected = this.selectedMapping.find(
                mapData => mapData.val.productID === item._id
              );
            }
            this.products.push({
              ...item,
              selected: isSelected ? true : false
            });
          });
          this.emptyData.status = false;
        } else {
          if (!isPage) {
            this.products = [];
            this.emptyData.status = true;
            this.emptyData.keyword = this.searchItem["@search.text"];
          } else {
            this.notMoreProducts = true;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.loadingMore = false;
      }
    },
    emptyResult(val) {
      this.emptyData = {
        status: val.status,
        keyword: val.keyword
      };
    },
    submitSearch() {
      this.search(this.form.search);
    },
    async getTaskResult() {
      try {
        let response = await this.$axios.get(API_ROUTES.taskResult(this.id));
        this.taskName = response.data.resource_name;
        if (response.data.type_b_file_url) {
          this.slowLineUrl = response.data.type_b_file_url;
          await this.getArtikles(this.slowLineUrl);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getArtikles(url) {
      let r = await axios(url);
      this.mappingData = r.data.unmatched;
      this.totalLength = r.data.unmatched.length + r.data.matched.length;
      this.orginalMappingData = _.clone(this.mappingData);
      this.mappingData = this.mappingData.map(item => {
        item.AISuggestion = item.AISuggestion.map(suggest => {
          var temp = Object.assign({}, suggest);
          temp.Wahrscheinlichkeit = Number(temp.Wahrscheinlichkeit * 100);
          if (parseInt(temp.Wahrscheinlichkeit) >= 75) {
            let index = this.groupArtikles.findIndex(item => item.val === 75);
            this.groupArtikles[index].productSum++;
          }
          if (parseInt(temp.Wahrscheinlichkeit) >= 85) {
            let dummy = this.groupArtikles.find(item => item.val === 85);
            dummy.productSum++;
          }
          if (parseInt(temp.Wahrscheinlichkeit) >= 95) {
            let dummy = this.groupArtikles.find(item => item.val === 95);
            dummy.productSum++;
          }
          return temp;
        });
        return item;
      });
      let percentTemps = [];
      this.mappingData.map(item => {
        return percentTemps.push(item);
      });

      this.mappingData = percentTemps;
      this.selectedMappingData = this.mappingData[this.selectedIndex] || {};
      this.getGroupArtikleLength();
      this.showEmptyArticles = true;
      this.$refs.emptyArticles.changeLoading(false);
    },
    async getGroupArtikleLength() {
      this.products = [];
      this.searchSuggestItem(this.selectedMappingData.AISuggestion);
      this.groupArtikles.map(group => {
        if (group.val === "all") {
          group.productSum = this.selectedMappingData.AISuggestion.length;
        }
      });
      //this.searchSuggestItem(this.products);
    },
    async groupArtikle(percent) {
      this.selectedPercent = percent;
      this.getGroupArtikleLength();
      if (percent == "all") {
        return (this.mappingData = _.clone(this.orginalMappingData));
      }
      let tmp = [];
      for (let i of this.orginalMappingData) {
        console.log(i);
        let isHave = i.AISuggestion.find(x => x.Wahrscheinlichkeit >= percent);
        if (isHave) {
          tmp.push(i);
        }
      }
      this.mappingData = tmp;
    },
    fileName(type) {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth();
      const day = d.getDay();
      const hours = d.getHours();
      const minutes = d.getMinutes();
      const seconds = d.getSeconds();
      let time = `${year}${month}${day}${hours}${minutes}${seconds}`;
      return `${time}-${this.taskName.split(".csv")[0]}-${type}.csv`;
    },
    async exportData() {
      if (this.getPercentGroupCountProdukte === 0) {
        //TODO: Ask to Katja
        return;
      }

      this.loading = true;
      try {
        let data = {
          range: this.selectedPercent
        };
        let response = await this.$axios.post(
          API_ROUTES.autoMappingExport(this.id),
          data
        );
        if (response) {
          this.selectedMapping.map(item => {
            let isHave = this.mappingData.findIndex(
              mapData => mapData.mappingID !== item.mappingID
            );
            if (isHave !== -1) {
              this.mappingData.splice(isHave, 1);
            }
          });
          let blob = new Blob([response], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.fileName("auto-mapping");
          link.click();
          this.selectedMapping = [];
          this.selectedMappingData = this.mappingData[0];
          this.products = [];
          /* let percentIndex = this.groupArtikles.findIndex(
            item => item.val === this.selectedPercent
          ); */
          this.groupArtikles = [
            {
              name: "95%",
              val: 95,
              productSum: 0,
              icon: true
            },
            {
              name: "85%",
              val: 85,
              productSum: 0,
              icon: true
            },
            {
              name: "75%",
              val: 75,
              productSum: 0,
              icon: true
            }
          ];
          this.selectedIndex = -1;
          this.mappingData = [];
          return (this.selectedMappingData = {});
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>