<template>
  <div id="snackbar">{{ text }}</div>
</template>

<script>
export default {
  name: "Toast",
  props: ['text']
}
</script>

<style scoped>
#snackbar {
  visibility: hidden;
  margin-left: -125px;
  background-color: #555555;
  color: #fff;
  text-align: center;
  border-radius: 18px;
  padding: 10px 20px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}
#snackbar.err {
  background-color: #ff5555 !important;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
</style>