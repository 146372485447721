<template>
  <div>
    <div class="card mb-4">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <p class="task-file-name">
          <span class="name">{{ fileName }}</span>
          <span class="format">.csv</span>
        </p>
        <p class="task-date">
          {{
            this.$dayjs(task.created_at)
              .locale("de")
              .format("DD MMM YYYY - HH:mm")
          }}
        </p>
      </div>
      <div class="card-body">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="fullWidth"
            :aria-valuenow="detail.slow_progress"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div class="task-metrics d-flex justify-content-between">
          <div class="proggress-percent">
            <div class="item">
              <p class="title text-primary">Fertig</p>
              <p class="desc text-primary">{{ detail.slow_progress || 0 }}%</p>
              <div
                @click="openCancelTaskModal(task.resource_id)"
                v-if="detail.fast_status !== 'success'"
                class="action close d-flex align-items-center"
              >
                <ph-x-circle :size="18" weight="bold" />
                <p class="link">Abbrechen</p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column gap-20">
            <div class="task-metrics-items d-flex justify-content-end gap-20">
              <div
                class="
                  item
                  d-flex
                  flex-column
                  align-items-center
                  justfiy-content-center
                  min-160
                "
              >
                <p class="title">Produkte gefunden</p>
                <p class="desc text-dark-grey">{{ metrics.totalMappingCount }}</p>
              </div>
              <div
                class="
                  item
                  d-flex
                  flex-column
                  align-items-center
                  justfiy-content-center
                  min-160
                "
              >
                <p class="title">In Bearbeitung</p>
                <p class="desc text-green">
                  {{
                    metrics.totalMappingCount - metrics.completedFastMappingCount
                  }}
                </p>
              </div>
              <div
                class="
                  item
                  d-flex
                  flex-column
                  align-items-center
                  justfiy-content-center
                  min-160 
                "
              >
                <p class="title">Manuelles Mapping</p>
                <p class="desc">
                  {{
                    `${metrics.completedMatchedTypeACount} / ${metrics.completedTypeACount}` ||
                      "-"
                  }}
                </p>
              </div>
              <div
                class="
                  item
                  d-flex
                  flex-column
                  align-items-center
                  justfiy-content-center
                  min-160
                "
              >
                <p class="title">Mapping erfolgreich</p>
                <p class="desc text-primary">
                  {{ metrics.completedFastMappingCount }}
                </p>
              </div>
            </div>
            <p v-if="task.current" class="text-right mr-4">
              {{ `${task.current + 1} von ${metrics.completedTypeACount} Artikel wurden zusammengeführt` }}
            </p>
            <div class="d-flex gap-20">
              <div class="min-160"></div>
              <div class="min-160"></div>
              <div class="min-160 text-center">
                <button 
                  class="btn btn-primary btn-sm" 
                  v-if="detail.slow_status === 'success' && metrics.completedMatchedTypeACount === metrics.completedTypeACount"
                  @click="$router.push({name: 'History'})"
                >
                  Fertig
                </button>
                <button class="btn btn-white btn-sm" v-else-if="detail.fast_status === 'success' && metrics.completedTypeACount > 0" @click="goManualMap">
                  <ph-note-pencil :size="18" weight="bold" />
                  Bearbeiten
                </button>
              </div>
              <div class="min-160 text-center">
                <button
                  class="btn btn-primary btn-sm"
                  :disabled="
                    loading.status && loading.type === 'BUTTON' ? true : false
                  "
                  v-if="
                    detail.fast_status === 'success' &&
                      metrics.completedFastMappingCount > 0
                  "
                  @click="downloadFile(detail.resource_id)"
                >
                  <ph-download-simple :size="18" weight="bold" />
                  {{
                    loading.status === true && loading.type === "BUTTON"
                      ? "Lädt.."
                      : "Exportieren"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <cancel-task-modal
        :id="task.resource_id"
        v-if="showModal"
        @close="showModal = false"
        @canceled="canceledTask"
      />
    </div>
  </div>
</template>

<script>
import CancelTaskModal from "@/components/Modals/CancelTaskModal.vue";
import API_ROUTES from "@/utils/API_ROUTES.js";

export default {
  props: {
    task: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      showModal: false,
      isGetTaskResult: false,
      loading: {
        status: true,
        type: null
      },
      isStart: true,
      detail: {
        created_at: null,
        fast_progress: null
      }
    };
  },
  components: {
    CancelTaskModal
  },
  computed: {
    whl() {
      return this.isStart;
    },
    getTask() {
      return this.task;
    },
    fullWidth() {
      return `width:${this.detail.slow_progress}%`;
    },
    fileName() {
      const name = this.task.resource_name;
      return name.split(".csv")[0];
    },
    metrics() {
      if (this.task.metrics) {
        return JSON.parse(this.task.metrics, true);
      }
      return {};
    }
  },
  methods: {
    canceledTask() {
      this.$emit("canceled", this.task.resource_id);
    },
    async downloadFile(id) {
      try {
        this.loading.status = true;
        this.loading.type = "BUTTON";
        let result = await this.$axios.get(API_ROUTES.getTaskResult(id));
        window.location = result.data.csv_url;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading.status = false;
        this.loading.type = null;
      }
    },
    goManualMap() {
      return this.$router.push(`/processing/empty-mapping?id=${this.task.resource_id}&type=${"klarfall"}`);
    },
    openCancelTaskModal(id) {
      this.showModal = true;
      console.log(id);
    },
    async getTaskResult() {
      try {
        let response = await this.$axios.get(
          API_ROUTES.taskResult(this.task.resource_id)
        );
        console.log(response);
      } catch (e) {
        console.log(e);
      }
    },
    async getTaskDetail() {
      try {
        this.loading.status = true;
        this.loading.type = "CARD";
        const response = await this.$axios.get(
          API_ROUTES.getTaskDetail(this.task.resource_id)
        );
        if (response.status === "success") {
          this.$emit("update:task", response.data.task);
          this.detail = response.data.task;
          this.task.metrics = response.data.task.metrics;
          if (response.data.task.fast_status === "success") {
            this.getTaskResult();
          }
          if (response.data.task.slow_status === "success") {
            this.isStart = false;
            setTimeout(() => {
              this.$emit("canceled", this.task.resource_id);
            }, 2000);
          }
        }
      } catch (e) {
        console.log(e);
        this.$set(this.task, "hidden", true);
        this.task.hidden = true;
      } finally {
        //this.loading.status = false;
        //this.loading.type = null;
      }
    }
  },
  beforeDestroy() {
    this.isStart = false;
  },
  async created() {
    while (this.whl) {
      if (!this.whl) {
        break;
      }
      await this.getTaskDetail();
      await new Promise(resolve => setTimeout(() => resolve(true), 5 * 1000));
    }
  }
};
</script>