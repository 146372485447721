<template>
  <div class="search-result-item relative" :class="{ selected: data.selected }">
    <div class="inner">
      <div
        v-if="loading"
        class="loading-wrapper w-100 bg-white h-100 pt-5 pb-5 z-index-1"
      >
        <div class="loader"></div>
      </div>
      <div v-if="!loading && detail" class="product-header">
        <div class="left">
          <div v-if="data.percent" class="match-rate">
            <span class="rate">{{ data.percent }}%</span>
            MAPPING WAHRSCHEINLICHKEIT
          </div>
        </div>
      </div>
      <div v-if="!loading && detail" class="product-info">
        <div class="items">
          <div class="d-flex w-100">
            <div class="item bordered d-none">
              <h4 class="title">Interne ID</h4>
              <p class="value">-</p>
            </div>
            <div class="item bordered">
              <h4 class="title">ARTIKELNAME</h4>
              <p class="value">
                {{ detail.modelName + " - " + detailName }}
              </p>
            </div>
            <div class="item bordered">
              <h4 class="title">MODELLNAME</h4>
              <p class="value">
                {{ detail.modelName }}
              </p>
            </div>
            <div class="item d-flex align-items-end">
              <div class="product-image absolute">
                <img @error="showDefaultPhoto" :src="productImage" alt="" />
              </div>
            </div>
          </div>
          <div class="w-100 d-flex mt-5">
            <div class="item bordered">
              <h4 class="title">ARTIKELNUMMER</h4>
              <p class="value">
                {{ detail.supplierArticleNumber || "-" }}
              </p>
            </div>
            <div class="item bordered">
              <h4 class="title">EAN</h4>
              <p class="value">{{ detail.ean || "-" }}</p>
            </div>
            <div class="item bordered">
              <h4 class="title">VAN</h4>
              <p class="value">
                {{ detail.van }}
              </p>
            </div>
          </div>
          <div class="w-100 d-flex mt-4">
            <div class="item bordered">
              <h4 class="title">Lieferant</h4>
              <p class="value">{{ detail.supplierName || "-" }}</p>
            </div>
            <div class="item bordered">
              <h4 class="title">HÖHE</h4>
              <p class="value">
                {{ detail.hoehe ? detail.hoehe + " cm" : "-" }}
              </p>
            </div>
            <div class="item bordered">
              <h4 class="title">Breite</h4>
              <p class="value">
                {{ detail.breite ? detail.breite + " cm" : "-" }}
              </p>
            </div>
          </div>
          <div class="w-100 d-flex">
            <div class="item bordered mt-4">
              <h4 class="title">Länge</h4>
              <p class="value">
                {{ detail.laenge ? detail.laenge + " cm" : "-" }}
              </p>
            </div>
            <div class="item bordered mt-4">
              <h4 class="title">DURCHMESSER</h4>
              <p class="value">
                {{ detail.durchmesser ? detail.durchmesser + " cm" : "-" }}
              </p>
            </div>
            <div class="item bordered mt-4">
              <h4 class="title">TIEFE</h4>
              <p class="value">
                {{ detail.tiefe ? detail.tiefe + " cm" : "-" }}
              </p>
            </div>
          </div>
          <div
            class="w-100 d-flex mt-4 justify-content-between align-items-start"
          >
            <div class="item w-50">
              <h4 class="title">ZusÄtzliche Maßangaben</h4>
              <p class="value">{{ "-" }}</p>
            </div>

            <div
              class="item col-4 d-flex justify-content-end"
              style="padding-left: 26px; padding-right: 0"
            >
              <button
                v-if="showBtn"
                @click="selectSave(data)"
                class="btn"
                :class="data.selected ? 'btn-primary' : 'btn-white'"
              >
                {{ data.selected ? "ausgewählt" : "auswählen" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="search-result-item-footer"
      @click="showDetail = !showDetail"
    >
      <p class="text-center text-primary">BESCHREIBUNG</p>
    </div>
    <div v-if="showDetail" class="detail w-100 ml-5 mb-5">
      {{ detail.description }}
    </div>
  </div>
</template>

<script>
import PIAxios from "@/utils/PIAxios.js";
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      detail: null,
      isSelected: false,
      showDetail: false
    };
  },
  computed: {
    detailName() {
      if (this.detail.productName) {
        return this.detail.productName;
      }
      return this.detail.name;
    },
    productImage() {
      return (
        this.imageProxy(
          (((this.detail.images || {}).value || [])[0] || {}).url
        ) || require("@/assets/images/missing_photo.svg")
      );
    }
  },
  created() {
    this.type = this.$route.query.type;
    this.loading = true;
    this.getProductData();
  },
  methods: {
    selectSave(data) {
      this.changeSelected(true);
      this.$emit("selectSave", data);
    },
    changeSelected(data) {
      this.isSelected = data;
    },
    async getProductData() {
      try {
        let response = await PIAxios.get(
          `/api/product/details?id=${this.data._id}`
        );
        this.detail = response;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    showDefaultPhoto(event) {
      event.target.src = require("@/assets/images/missing_photo.svg");
    },
    imageProxy(id, width = 300, height = 400) {
      return `https://imgproxy.germanywestcentral.cloudapp.azure.com/AfrOrF3gWeDA6VOlDG4TzxMv39O7MXnF4CXpKUwGqRM/auto/${width}/${height}/sm/0/plain/${id}@png`;
    }
  }
};
</script>